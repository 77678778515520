<template>
    <div class="flex flex-col  bgSecondary5">
        <div id="about" class="h-20"></div>
        <PagestartComponent to="/#home" getRouter="Home" thisPage="About Us" title="About Us" content="Falcon Aerospace is an integrated aviation company with maintenance/repair/overhaul services" />
        <PictorialdescriptionComponent id="history"/>
        <div id="mission"></div>
        <MissionandvisionComponent/>
        <SlideimageComponent />
        <div id="team"></div>
        <TeamComponents/>
        <div class=" w-full flex flex-col items-center justify-center">
            <p id="title" class="tohoma font-bold primary ">Approvals</p>
            <OtomoticscrollComponent id="approvals" :imagesList="images" lengthArray="4" />
        </div>
        <div class=" w-full flex flex-col items-center justify-center mb-28">
            <p id="title" class="tohoma font-bold primary  mb-8">Memberships</p>
            <OtomoticscrollComponent id="memberships" :imagesList="images2" lengthArray="2" />
        </div>
    </div>
</template>
<script>
import MissionandvisionComponent from "@/components/MissinandvisionComponent.vue";
import PagestartComponent from "@/components/PagestartComponent.vue"
import PictorialdescriptionComponent from "@/components/PictorialdescriptionComponent.vue"
import OtomoticscrollComponent from "@/components/OtomoticscrollComponent.vue";
import SlideimageComponent from "@/components/SlideimageComponent.vue"
import TeamComponents from "@/components/TeamComponents.vue";
export default {
    components: {
        PagestartComponent,
        PictorialdescriptionComponent,
        MissionandvisionComponent,
        SlideimageComponent,
        OtomoticscrollComponent,
        TeamComponents
    },
    name: "AboutPage",
    data() {
        return {
            images: [
                {
                    name: "logo-6.png",
                    pdf: "SHGM.pdf"
                },
                {
                    name: "logo-5.png",
                    pdf: "SAN MARINO.pdf"                },
                {
                    name: "logo-3.png",
                    pdf: ""
                },
                {
                    name: "logo-2.png",
                    pdf: ""
                },
                {
                    name: "logo-9.png",
                    pdf: ""
                }
            ],
            images2: [
                {
                    name: "logo-10.png",
                    pdf: "ebaa.pdf"
                },
                {
                    name: "logo-8.png",
                    pdf: ""
                }
            ]
        }
    },
    mounted() {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth'});
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    watch: {
    // URL'deki hash değerini izle
    '$route.hash': function (newHash, oldHash) {
      // Eğer hash değeri değiştiyse
      if (newHash !== oldHash) {
        // Yeni hash değerine göre ilgili öğeyi al
        const element = document.querySelector(newHash);
        if (element) {
          // Öğeyi görünür hale getir
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
}
</script>
<style scoped>
#title {
    font-size: 32px;
}

@media(max-width:480px) {
    #title {
        font-size: 20px;
    }
}
</style>