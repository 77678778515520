<template>
    <div class="w-full md:pt-28 pt-20 md:pl-20 md:pr-20 pr-5 pl-5 flex flex-col items-center justify-center mb-20">
        <p id="title" class="tohoma font-bold">Mission & Vision</p>
        <div class="flex md:flex-row flex-col w-full mt-9 items-center justify-center">
            <div class="flex flex-col 2xl:w-1/3 md:w-1/2 w-full p-6 mt-2 mb-2 card-shadow md:h-72 h-auto">
                <p class="primary tohoma font-bold md:text-2xl text-sm pt-3">Mission</p>
                <p class="helvatica font-normal md:text-base text-xs secondary">To present operation and maintenance/modification services to our business jet customers by always complying with national and international regulations and by putting customer satisfaction, quality work and punctuality as the first priority.</p>
            </div>
            <div class="flex 2xl:w-1/3 flex-col md:w-1/2 w-full p-6 card-shadow mt-2 mb-2 md:h-72 h-auto">
                <p class="primary tohoma font-bold md:text-2xl text-sm pt-3">Vision</p>
                <p class="helvatica font-normal md:text-base text-xs secondary">To become one of the biggest 10th organisation in the world's business jet sector in the field of operational and maintenance/modification services by using national resources at maximum level.</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
#title{
    font-size: 32px;
}
@media(max-width: 480px) {
    #title{
        font-size: 16px;
    }
}
</style>