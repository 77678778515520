<template>
    <div class="flex flex-col  bgSecondary5">
        <div id="maintenance" class="h-20"></div>
        <PagestartComponent to="/#home" getRouter="Home" thisPage="Maintenance Services" title="Maintenance Services"
            content="Currently we perform line maintenance including unscheduled and scheduled tasks for Bombardier BD-700 , CL-600-2B16 & 2B19 and Hawker 750/800XP/850XP/900XP series and plan to expand our capability list to cover other types." />
        <div id="aircraft" class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center">
            <p id="title" class="tohoma font-bold primary mb-8">Aircraft</p>
            <p class=" md:text-base text-sm secondary">Currently we perform line maintenance on business jets and plan to
                expand our capabilities to cover other
                types.</p>
            <div class="flex md:flex-row flex-col items-center justify-center mt-8">
                <div class="flex flex-col md:w-1/2 w-full md:pr-2.5">
                    <img class="mb-6 rounded-lg" src="../assets/maintenance1.jpg">
                    <p class="mb-6 tohoma primary font-bold md:text-2xl text-sm">Bombardier Global</p>
                    <p class="h-20 text-color md:text-base text-xs mb-8 helvatica font-normal">We perform line maintenance
                        including
                        scheduled and unscheduled tasks on Bombardier BD-700 Series
                        (RRD BR700-710) and plan to cover base maintenance in Q3 of 2023.</p>
                </div>
                <div class="flex flex-col md:w-1/2 w-full md:pl-2.5">
                    <img class="mb-6  rounded-lg" src="../assets/challenger.jpg">
                    <p class="mb-6 tohoma primary font-bold md:text-2xl text-sm">Bombardier Challenger</p>
                    <p class="h-20 text-color md:text-base text-xs mb-8 helvatica font-normal">We perform line maintenance
                        including
                        scheduled and unscheduled tasks on Bombardier CL-600-2B16(604 variant)(GE CF34)
                        and Bombardier CL-600-2B19 (GE CF34) and plan to cover base maintenance in Q3 of 2023.</p>
                </div>
            </div>
            <div class="flex md:flex-row flex-col items-center justify-center mt-8">
                <div class="flex flex-col md:w-1/2 w-full md:pr-2.5">
                    <img class="mb-6 rounded-lg" src="../assets/Depositphotos_13582872_XL.jpg">
                    <p class="mb-6 tohoma primary font-bold md:text-2xl text-sm">Hawker</p>
                    <p class="h-20 text-color md:text-base text-xs mb-8 helvatica font-normal">We perform line maintenance
                        including
                        scheduled and unscheduled tasks on Hawker Beechcraft BAe 125 Series
                        750/800XP/850XP/900XP (Honeywell TFE731) and plan to cover base maintenance in Q3 of 2023.</p>
                </div>
                <div class="flex flex-col md:w-1/2 w-full md:pl-2.5">
                    <img class="mb-6 rounded-lg" src="../assets/gulfstream.jpg">
                    <p class="mb-6 tohoma primary font-bold md:text-2xl text-sm">Gulfstream</p>
                    <p class="h-20 text-color md:text-base text-xs mb-8 helvatica font-normal">We plan to add Gulfstream
                        GIV/GIV-SP/GIV-X Series (RRD Tay) and
                        Gulfstream GV basic model/GV-SP Series (RRD BR710) into our capability list in Q2 of 2023.</p>
                </div>
            </div>
        </div>
        <div id="engine"
            class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 md:mt-8 mt-10">
            <p id="title" class="primary font-bold tohoma mb-8">ENGINE & APU SUPPORT</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">On-wing engine maintenance tasks are performed by qualified and properly rated maintenance technicians also on engines under special care programs</p>
            <TwoimageComponents :images="engineImages" />
        </div>
        <div id="purchase"
            class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 md:mt-8 mt-10">
            <p id="title" class="primary font-bold tohoma mb-8">PRE-PURCHASE INSPECTIONS</p>
            <!-- <p class="helvatica font-normal secondary md:text-base text-xs mb-8">On-wing engine maintenance tasks are performed by qualified and properly rated maintenance technicians also on engines under special care programs</p> -->
            <TwoimageComponents :images="purchaseImages" />
        </div>
        <ImageandTextComponent id="avionics"  title="Avionics"
            content="We are able to perform avionic modifications suchs as IFE, SATCOM and internet as well as any scheduled and unscheduled system or functional tests on avionics systems."
            image="Avionics.jpg" />
        <!-- <div class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 mt-28">
            <p id="title" class="primary font-bold tohoma mb-8">Components</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">Off-wing maintenance of the removed
                components
                are carried out by Part-145 approved contracted organisations or OEMs.</p>
            <TwoimageComponents :images="componentsImages"  id="components"/>
        </div> -->
        <ImageandTextComponent id="cabin" title="INTERIOR & EXTERIOR CLEANING"
            content="We are able to perform Interior & Exterior Detailed Cleaning ."
            image="cabin.jpg" />
        <!-- <div id="camo" class="flex flex-col md:mr-20 md:ml-20 mr-5 ml-5 justify-center items-center text-3xl mb-28 mt-28">
            <p id="title" class="primary font-bold tohoma mb-8">CAMO Services</p>
            <p class="helvatica font-normal secondary md:text-base text-xs mb-8">Falcon Aerospace is capable to perform
                Part-M maintenance management
                activities including airworthiness reviews for business jet operators and owners.</p>
            <TwoimageComponents :images="camoservicesImages" />
        </div> -->
    </div>
</template>
<script>
import PagestartComponent from "@/components/PagestartComponent.vue"
import TwoimageComponents from "@/components/TwoimageComponents.vue";
import ImageandTextComponent from "@/components/ImageandTextComponent.vue";
export default {
    components: {
        PagestartComponent,
        TwoimageComponents,
        ImageandTextComponent
    },
    data() {
        return {
            engineImages: [
                "HomeServices3.jpg",
                "Engine2.png"
            ],
            purchaseImages: [
                "110.jpg",
                "111.jpg"
            ],
            componentsImages: [
                "components1.jpg",
                "components2.jpg"
            ],
            camoservicesImages: [
                "camoservices1.jpg",
                "HomeServices1.jpg"
            ]
        }
    },
    mounted() {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo(0, 0);
        }
    },
    watch: {
    // URL'deki hash değerini izle
    '$route.hash': function (newHash, oldHash) {
      // Eğer hash değeri değiştiyse
      if (newHash !== oldHash) {
        // Yeni hash değerine göre ilgili öğeyi al
        const element = document.querySelector(newHash);
        if (element) {
          // Öğeyi görünür hale getir
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }
}
</script>
<style scoped>
.text-color {
    color: #4F4F4F;
}

#title {
    font-size: 32px;
}

@media(max-width:480px) {
    #title {
        font-size: 16px;
    }
}</style>