<template>
  <div class="flex flex-col  items-center z-30 fixed">
    <header class="bg-white shadow w-full h-20 flex justify-center items-center">
      <div class="container mx-auto relative flex justify-between  w-full">
        <router-link to="/#home">
          <img src="../assets/FalconAerospacelogo.png" class="w-64 flex ">
        </router-link>
        <button @click="close" class="p-5"><img src="../assets/icons8-menu-rounded-100.png" class="w-6"></button>
        <nav id="mainnav" class="mainnav mt-12">
          <ul class="menu">
            <li class="home">
              <router-link id="link" to="/#home"
                class="font-bold text-base secondary2 mr-6 cursor-pointer hover:red-text"
                :class="{ 'text-black': $route.path === '/' || $route.path === '/news/0' || $route.path === '/news/2' || $route.path === '/news/1' }">Home</router-link>
            </li>
            <li><router-link exact id="link" to="/about#about"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/about' }">About Us</router-link>
              <ul class="submenu">
                <li><router-link id="link" to="/about#history"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">History</router-link></li>
                <li><router-link id="link" to="/about#mission"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Mission & Vision</router-link></li>
                <li><router-link id="link" to="/about#team"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Team</router-link></li>
                <li><router-link id="link" to="/about#approvals"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer"
                    @click.prevent="scrollToAnchor('approvals')">Approvals</router-link></li>
                <li><router-link id="link" to="/about#memberships"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer"
                    @click.prevent="scrollToAnchor('memberships')">Memberships</router-link></li>
              </ul>
            </li>
            <li><router-link id="link" to="/bombardierchallanger604#bombardierchallanger"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/bombardierchallanger604' || $route.path === '/bombardierchallanger850' || $route.path === '/bombardierglobalSeries' || $route.path === '/hawker' || $route.path === '/embarerLegacy'  }">Aircraft</router-link>
              <ul class="submenu">
                <li>
                  <router-link id="link" to="/bombardierchallanger604#bombardierchallanger"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Challenger 604
                  </router-link>
                </li>
                <li>
                  <router-link id="link" to="/bombardierchallanger850#challanger"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Challenger 850
                  </router-link>
                </li>
                <li>
                  <router-link id="link" to="/bombardierglobalSeries#challanger"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Global Series
                  </router-link>
                </li>
                <li>
                  <router-link id="link" to="/hawker#hawker"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Hawker
                  </router-link>
                </li>
                <li>
                  <router-link id="link" to="/embarerLegacy#embarerlegacy"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">EMBRAER 135/145
                    LEGACY 600/650
                  </router-link>
                </li>
              </ul>
            </li>
            <li><router-link id="link" to="/maintenanceservices#maintenance"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/maintenanceservices' }">Maintenance Services</router-link>
              <ul class="submenu">
                <li> <router-link id="link" to="/maintenanceservices#aircraft"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Aircraft</router-link>
                </li>
                <li> <router-link id="link" to="/maintenanceservices#engine"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Engine & Apu Support</router-link>
                </li>
                <li> <router-link id="link" to="/maintenanceservices#avionics"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Avionics</router-link>
                </li>
                <li> <router-link id="link" to="/maintenanceservices#purchase"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Pre-Purchase Inpections</router-link>
                </li>
                <li> <router-link id="link" to="/maintenanceservices#cabin"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Interior & Exterior Cleaning</router-link>
                </li>
              </ul><!-- /.submenu -->
            </li>
            <li><router-link id="link" to="/aogsupport#aogsupport"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/aogsupport' }">AOG
                Support</router-link>
              <ul class="submenu">
                <li><router-link id="link" to="/aogsupport#maintenance"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Maintenance</router-link></li>
                <li><router-link id="link" to="/aogsupport#parts"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Parts</router-link></li>
              </ul><!-- /.submenu -->
            </li>
            <li><router-link id="link" to="/careers#careers" class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/careers' || $route.path === '/careers/licensed' || $route.path === '/careers/projectengineer' }">Careers</router-link>
              <ul class="submenu">
                <li><router-link id="link" to="/careers#job"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">Job Opennings</router-link></li>
                <li><router-link id="link" to="/careers#general"
                    class="font-bold text-base secondary2 mr-6 cursor-pointer">General Application Form</router-link>
                </li>
              </ul><!-- /.submenu -->
            </li>
            <li><router-link id="link" to="/contactus#contact"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                :class="{ 'text-black': $route.path === '/contactus' }">Contact
                Us</router-link></li>
          </ul><!-- /.menu -->
        </nav>
      </div>
    </header>
    <div v-if="closeMenu" id="hamburgerMenu"
      class="flex flex-col w-full primary2 pt-5 pb-5 h-1/3 z-30 text-center items-center justify-center  mb-6 relative">
      <div class="flex flex-row">
        <router-link to="/#home" @click="close" id="mobileLink"
          class=" font-bold text-base  secondary3 mr-6 cursor-pointer hover:red-text pb-1">Home</router-link>
      </div>
      <div class="flex flex-col w-full justify-center items-center">
        <div class="flex flex-row">
          <router-link @click="close" to="/about#about" id="mobileLink"
            class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">About Us</router-link>
          <button @click="subMenuOpen(1)"><img class="w-4" src="../assets/subMenu.png"></button>
        </div>
        <div v-if="subMenu == 1" class="flex flex-row bg-white w-full justify-center items-center">
          <ul class="submenu">
            <li><router-link @click="close" id="link" to="/about#history"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">History</router-link></li>
            <li><router-link @click="close" id="link" to="/about#mission"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Mission & Vision</router-link></li>
            <li><router-link @click="close" id="link" to="/about#team"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Team</router-link></li>
            <li><router-link @click="close" id="link" to="/about#approvals"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                @click.prevent="scrollToAnchor('approvals')">Approvals</router-link></li>
            <li><router-link @click="close" id="link" to="/about#memberships"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                @click.prevent="scrollToAnchor('memberships')">Memberships</router-link></li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-center">
        <div class="flex flex-row">
          <router-link @click="close" to="/bombardierchallanger604#challanger" id="mobileLink"
            class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">Aircraft</router-link>
          <button @click="subMenuOpen(6)"><img class="w-4" src="../assets/subMenu.png"></button>
        </div>
        <div v-if="subMenu == 6" class="flex flex-row bg-white w-full justify-center items-center">
          <ul class="submenu">
            <li><router-link @click="close" id="link" to="/bombardierchallanger604#bombardierchallanger"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Challenger 604</router-link></li>
            <li><router-link @click="close" id="link" to="/bombardierchallanger850#bombardierchallanger"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Challenger 850</router-link></li>
            <li><router-link @click="close" id="link" to="/bombardierglobalSeries#globalSeries"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Bombardier Global Series</router-link></li>
            <li><router-link @click="close" id="link" to="/hawker#hawker"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                @click.prevent="scrollToAnchor('approvals')">Hawker</router-link></li>
            <li><router-link @click="close" id="link" to="/embarerLegacy#embarerlegacy"
                class="font-bold text-base secondary2 mr-6 cursor-pointer"
                @click.prevent="scrollToAnchor('memberships')">EMBRAER 135/145
                LEGACY 600/650</router-link></li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-center">
        <div class="flex flex-row items-center justify-center">
          <router-link @click="close" to="/maintenanceservices#maintenance" id="mobileLink"
            class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">Maintenance
            Services</router-link>
          <button @click="subMenuOpen(2)"><img class="w-4" src="../assets/subMenu.png"></button>
        </div>
        <div v-if="subMenu == 2" class="flex flex-row bg-white w-full justify-center items-center">
          <ul class="submenu">
            <li> <router-link @click="close" id="link" to="/maintenanceservices#aircraft"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Aircraft</router-link>
            </li>
            <li> <router-link @click="close" id="link" to="/maintenanceservices#engine"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Engine & Apu Support</router-link>
            </li>
            <li> <router-link @click="close" id="link" to="/maintenanceservices#avionics"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Avionics</router-link>
            </li>
            <li> <router-link @click="close" id="link" to="/maintenanceservices#cabin"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Interior & Exterior Cleaning</router-link>
            </li>
            <li> <router-link @click="close" id="link" to="/maintenanceservices#purchase"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Pre-Purchase Inpections</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-center">
        <div class="flex flex-row items-center justify-center">
          <router-link @click="close" to="/aogsupport#aogsupport" id="mobileLink"
            class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">AOG
            Support</router-link>
          <button @click="subMenuOpen(4)"><img class="w-4" src="../assets/subMenu.png"></button>
        </div>
        <div v-if="subMenu == 4" class="flex flex-row bg-white w-full justify-center items-center">
          <ul class="submenu">
            <li><router-link @click="close" id="link" to="/aogsupport#maintenance"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Maintenance</router-link></li>
            <li><router-link @click="close" id="link" to="/aogsupport#parts"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Parts</router-link></li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col w-full justify-center items-center">
        <div class="flex flex-row items-center justify-center">
          <router-link @click="close" to="/careers#careers" id="mobileLink"
            class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">Careers</router-link>
          <button @click="subMenuOpen(5)"><img class="w-4" src="../assets/subMenu.png"></button>
        </div>
        <div v-if="subMenu == 5" class="flex flex-row bg-white w-full justify-center items-center">
          <ul class="submenu">
            <li><router-link @click="close" id="link" to="/careers#job"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">Job Opennings</router-link></li>
            <li><router-link @click="close" id="link" to="/careers#general"
                class="font-bold text-base secondary2 mr-6 cursor-pointer">General Application Form</router-link></li>
          </ul>
        </div>
      </div>
      <div>
        <router-link @click="close" to="/contactus#contact" id="mobileLink"
          class=" font-bold text-base secondary3 mr-6 cursor-pointer pb-1">Contact Us</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      closeMenu: false,
      previous: null,
      subMenu: null
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.closeMenu = !this.closeMenu
    },
    scrollToAnchor(anchor) {
      const element = document.querySelector(`#${anchor}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    subMenuOpen(item) {
      //item=1
      if (item == this.previous) {
        this.subMenu = null;
      } else {
        this.subMenu = item;
      }
      this.previous = this.subMenu
    }
  },
};
</script>
<style scoped>
#link:hover {
  color: #CC0605;
  transition: 1s;
}

#mobileLink {
  color: white !important;
}

.text-black {
  color: black !important;
}

@media (max-width: 1024px) {

  /* Bu boyuttan küçük cihazlar için stiller buraya gelecek */
  #mainnav {
    display: none !important;
    /* Navbar menüsünü gizle */
  }

  button {
    display: block;
    /* Hamburger menü butonunu göster */
  }
}

@media (min-width: 1025px) {

  /* Bu boyuttan büyük cihazlar için stiller buraya gelecek */
  nav {
    display: flex;
    /* Navbar menüsünü göster */
  }

  button {
    display: none;
    /* Hamburger menü butonunu gizle */
  }
}



nav {
  display: block;
}

#mainnav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#mainnav>ul>li {
  display: inline-block;
}

#mainnav ul li {
  position: relative;
}


#mainnav>ul>li>a {
  position: relative;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#mainnav ul.submenu {
  position: absolute;
  left: 0;
  top: 150%;
  width: 300px;
  text-align: left;
  background-color: #222222;
  z-index: 9999;
  filter: alpha(opacity=0);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
}

#mainnav ul.submenu li:first-child {
  border-top: none;
}

#mainnav ul.submenu>li {
  border-top: 1px solid #333333;
}

#mainnav ul li ul li {
  margin-left: 0;
}

#mainnav ul.submenu>li>a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0 0 0 20px;
  line-height: 45px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#mainnav ul li:hover>ul.submenu {
  top: 100%;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  visibility: visible;
}
</style>