<template>
    <div class=" flex flex-col items-center w-full" id="bombardierchallanger">
        <img
          src="../assets/challangerust.jpg"
          alt="Top Image"
          class="w-full fixed image md:mt-2 mt-16"
        />
        <div class="w-full text-black bg-white content py-10 px-10">
    <h1 class="text-4xl font-bold text-center mb-6">
        CHALLENGER MAINTENANCE SERVICES
    </h1>
    <h2 class="text-2xl font-semibold text-center mb-4">
        FULL SCOPE OF MPD
    </h2>
    <p class="text-lg leading-relaxed text-center mb-8">
        Line & Base Maintenance includes all inspection & checks as per AMM / MPD, including out of phase check, repairs (including AD’s and SB’s), modifications (including AD’s and SB’s), testing and defect rectification.
    </p>
    <ul class="list-disc list-inside space-y-2 mt-5">
        <li>Major Repairs / Alterations</li>
        <li>CHALLENGER REPAIR SPECS</li>
        <li>Full suite major Inspections and Repairs by Certified Professionally Trained Technicians</li>
        <li>All Major Inspections And Repairs</li>
        <li>AOG Parts Support</li>
        <li>Component And Accessory Overhaul And Exchange</li>
        <li>Professionally Trained Technicians</li>
        <li>Interior & Exterior Cleaning</li>
        <li>Honeywell and GE Engine Support, Removal and Reinstallation / Rigging</li>
        <li>Avionics Modification and Troubleshooting</li>
        <li>Thrust Reverser On Condition Inspections</li>
        <li>Road Trip Support</li>
        <li>Avionics Service and Installation</li>
        <li>Aircraft Internet Modifications (KU and KA Band Modifications)</li>
        <li>Engine / APU Support</li>
        <li>Composite Repair</li>
    </ul>
</div>
    </div>
  </template>
  
<script>
export default {
    
    data() {
        return {
            
        }
    },
    watch: {
    // URL'deki hash değerini izle
    '$route.hash': function (newHash, oldHash) {
      // Eğer hash değeri değiştiyse
      if (newHash !== oldHash) {
        // Yeni hash değerine göre ilgili öğeyi al
        const element = document.querySelector(newHash);
        if (element) {
          // Öğeyi görünür hale getir
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }

}
</script>
<style scoped>
.image{
    z-index: -1;
}
.content {
    margin-top: 37rem;
}

/* Mobil cihazlar için */
@media only screen and (max-width: 768px) {
    .content {
        margin-top: 15rem; /* Mobil için daha düşük bir değer */
    }
}
</style>