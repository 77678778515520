<template>
    <div id="slideContainer" class="flex w-full items-center justify-center relative">
        <!-- <div id="buttons" class="z-20 absolute">
            <div @click="slideClick(index,item)" :class="{ 'active': selectedButton === index }" class="button" v-for="(item,index) in slides" :key="index"></div>
        </div> -->
       <div class="w-full relative">
        <img class="w-full h-full" :src="slide[0].image">
        <div v-if="slide[0].content" id="content">
            <p id="title" class="secondary5 font-bold tohoma md:mb-8">{{ slide[0].title }}</p>
            <p id="contentp" class="md:text-base  secondary5 helvatica font-bold">{{ slide[0].content }}</p>
        </div>
       </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            param:0,
            selectedButton:0,
            active:false,
            slides: [
                // {
                //     title: '',
                //     content: '',
                //     image:  require(`../assets/EBACE_final_230406-2.jpg`),
                //     name:"active"
                // },
                // {
                //     title: 'Dedicated to Your Jet',
                //     content:"“As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. “ ",
                //     image:  require(`../assets/HomepageSlider.png`),
                //     name:"slide2"
                // },
                // {
                //     title: 'Dedicated to Your Jet',
                //     content:"“As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. “ ",
                //     image:  require(`../assets/homeSlide3.png`),
                //     name:"slide2"
                // },
                // {
                //     title: 'Dedicated to Your Jet',
                //     content:"“As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. “ ",
                //     image:  require(`../assets/homeSlide4.png`),
                //     name:"slide2"
                // },
                {
                    title: 'Dedicated to Your Jet',
                    content:"“As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. “ ",
                    image:  require(`../assets/FalconHome0008.png`),
                    name:"slide2"
                }
            ],
            slidesMobile: [
                // {
                //     title: '',
                //     content: '',
                //     image:  require(`../assets/EBACE_final_230406-2.jpg`),
                //     name:"active"
                // },
                // {
                //     title: 'Dedicated to Your Jet',
                //     content:"As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. ",
                //     image:  require(`../assets/homePageSliderMobile.jpg`),
                //     name:"slide2"
                // },
                // {
                //     title: 'Dedicated to Your Jet',
                //     content:"As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. ",
                //     image:  require(`../assets/homeSlideMobile3.jpg`),
                //     name:"slide2"
                // },
                {
                    title: 'Dedicated to Your Jet',
                    content:"As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. ",
                    image:  require(`../assets/HomeSlideMobileimg4.jpg`),
                    name:"slide2"
                }
                //homePageSliderMobile.jpg
            ],
            slide:[
            {
                    title: 'Dedicated to Your Jet',
                    content:"“As Falcon Aerospace, our experienced team is fully dedicated to your jet to support your scheduled and unscheduled maintenance needs as 7/24 basis. “ ",
                    image:  require(`../assets/FalconHome0008.png`),
                    name:"slide2"
                }    
            // {
            //         title: '',
            //         content: '',
            //         image:  require(`../assets/EBACE_final_230406-2.jpg`),
            //         name:"slide1"
            //     },
            ]
        }
    },
    mounted() {
        if(window.screen.width < 768){
            this.slides=this.slidesMobile
        }
    // setInterval(() => {
    //     this.slideClick(this.param, this.slides[this.param])
    // }, 3000);
},

    methods: {
        slideClick(index,item){
            console.log(index,item)
            this.selectedButton=index;
            this.slide[0]=item
            if(this.param==this.slides.length-1){
                console.log("param:",this.param);
                this.param=0;
            }else{
                this.param=this.param+1
            }
        }
    },

}
</script>
<style scoped>
#content{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 6%;
    left: 5%;
    width: 50%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
}
#buttons{
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: 32%;
    width: 100%;
}
#title{
    font-size: 40px;
}

#buttons .button{
    background-color: #C2C2C2;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

#slideContainer{
    height: 30rem;
}
img{
    max-height: 30rem;
    max-width: 100%;
}
.active{
    background: #C2C2C2 !important;
    width: 32px !important;
    height: 10px !important;
    border-radius: 10px !important;
}
/*
#content {
    
    animation-name: slideInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(-50%);
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(-10%);
        opacity: 1;
    }
}
*/
@media (max-width: 480px) {
    #title{
        font-size: 20px;
    }
    #content{
        bottom: 1%;
        width: 100%;
        height: 90px;
    }
    #contentp{
        font-size: 10px;
    }
    #slideContainer{
       height: 16rem;
    }
    img{
        max-height: 25rem;
    }
    #buttons{
        margin-top: 71.5%;
    }
}
@media (min-width: 1536px) {
    #content{
        bottom: 12%;
        width: 60%;
    }
    #slideContainer{
    height: 45rem;
    }
    #buttons{
        bottom: 3%;
    }
    img{
       max-height: 45rem;
    }
}
</style>