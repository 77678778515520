<template>
    <div class=" flex flex-col items-center w-full" id="embarerlegacy">
        <img src="../assets/challangerust.jpg" alt="Top Image" class="w-full fixed image md:mt-2 mt-16" />
        <div class="w-full text-black bg-white content py-10 px-10">
            <h1 class="text-4xl font-bold text-center mb-6">
                EMBRAER- LEGACY MAINTENANCE SERVICES
            </h1>
            <h2 class="text-2xl font-semibold text-center mb-4">
                Embraer EMB-135/145, LEGACY 600/650 Base Maintenance is limited to up to and including S72 and LU72
                packages, engine tasks, APU tasks, out of phase tasks, prior winter tasks, authorization requirement
                tasks (up to and including 72M and 8000H), component limited requirement tasks (up to and including 72M
                and 10000H)
            </h2>
            <h3 class="text-xl font-semibold text-center mb-4">
                EMBRAER- LEGACY REPAIR SPECS
            </h3>
            <ul class="list-disc list-inside space-y-2 mt-5">
                <li>Major Inspections and Repairs by Certified Professionally Trained Technicians</li>
                <li>AOG Parts Support</li>
                <li>Component And Accessory Overhaul And Exchange</li>
                <li>Professionally Trained Technicians</li>
                <li>Interior & Exterior Cleaning</li>
                <li>Rolls Royce and Pratt & Whitney Engine Support, Removal and Reinstallation / Rigging</li>
                <li>Avionics Modification and Troubleshooting</li>
                <li>Thrust Reverser On Condition Inspections</li>
                <li>Road Trip Support</li>
                <li>Avionics Service and Installation</li>
                <li>Aircraft Internet Modifications</li>
                <li>Engine / APU Support</li>
                <li>Composite Repair</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {

        }
    },
    watch: {
        // URL'deki hash değerini izle
        '$route.hash': function (newHash, oldHash) {
            // Eğer hash değeri değiştiyse
            if (newHash !== oldHash) {
                // Yeni hash değerine göre ilgili öğeyi al
                const element = document.querySelector(newHash);
                if (element) {
                    // Öğeyi görünür hale getir
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }

}
</script>
<style scoped>
.image {
    z-index: -1;
}

.content {
    margin-top: 37rem;
}

/* Mobil cihazlar için */
@media only screen and (max-width: 768px) {
    .content {
        margin-top: 15rem;
        /* Mobil için daha düşük bir değer */
    }
}
</style>