import { createRouter, createWebHistory } from "vue-router"
import HomePage from "@/pages/HomePage"
import AboutPage from "@/pages/AboutPage"
import MaintanenceservicesPages from "@/pages/MaintenanceservicesPage"
import OperationservicesPage from "@/pages/OperationservicesPage"
import AogsupportPage from "@/pages/AogsupportPage"
import CareersPage from "@/pages/CareersPage"
import ProjectengineerComponents from "@/components/ProjectengineerComponents"
import LicansedaircraftComponent from "@/components/LicancedaircraftComponents"
import NewsComponent from "@/components/NewsComponents"
import ContactusPage from "@/pages/ContactusPage"
import BombardierChallanger604 from "@/pages/BombardierChallanger604.vue"
import BombardierChallenger850 from "@/pages/BombardierChallenger850.vue"
import BombardierglobalSeries from "@/pages/BombardierglobalSeries.vue"
import HawkerPage from "@/pages/HawkerPage.vue"
import EmbarerLegacy from "@/pages/EmbarerLegacy.vue"


const routes = [
    {
        name: "home",
        path: "/",
        component: HomePage
    },
    {
        name: "about",
        path: "/about",
        component: AboutPage
    },
    {
        name: "maintanenceservices",
        path: "/maintenanceservices",
        component: MaintanenceservicesPages
    },
    {
        name: "operationservices",
        path: "/operationservices",
        component: OperationservicesPage
    },
    {
        name: "aogsupport",
        path: "/aogsupport",
        component: AogsupportPage
    },
    {
        name: 'careers',
        path: '/careers',
        component: CareersPage,
    },
    {
        name: 'projectengineer',
        path: '/careers/projectengineer',
        component: ProjectengineerComponents
    },
    {
        name:'Licensed',
        path:'/careers/licensed',
        component:LicansedaircraftComponent
    },
    {
        name:"news",
        path:"/news/:id",
        component:NewsComponent
    },
    {
        name:"contactus",
        path:"/contactus",
        component:ContactusPage
    },
    {
        name:"bombardierchallanger604",
        path:"/bombardierchallanger604",
        component:BombardierChallanger604
    },
    {
        name:"bombardierchallanger850",
        path:"/bombardierchallanger850",
        component:BombardierChallenger850
    },
    {
        name:"bombardierglobalSeries",
        path:"/bombardierglobalSeries",
        component:BombardierglobalSeries
    },
    {
        name:"hawkerPage",
        path:"/hawker",
        component:HawkerPage
    },
    {
        name:"EmbarerLegacy",
        path:"/embarerLegacy",
        component:EmbarerLegacy
    },


]
const router = createRouter({
    routes,
    history: createWebHistory()
})

export default router;